@tailwind base;
@tailwind components;
@tailwind utlities;

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}

* {
  margin: 0;
  background-color: #f4f4f4;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  /* box-sizing: border-box; */
}

:root {
  --company-logo-color: red: 

  --light-blue-color: #b7e0ff;
  --border-blue-color: #789dbc;

  /* Scroll Bar */
  --scroll-bar-color: gray;
  --scroll-bar-hover-color: #007fff;

  /* OLIVE BLACK */

  --black-color: #3B3C36;
  --white-color: white;

  /* CONTEXT MENU HOVER COLOR */

  --context-menu-bg-color-hover: #f5f5f5;

  /* ICONS COLOR IN HOME SCREEN */
  --color-of-icons: #5072A7;

  /* Business settings colors */
  --tab-color: #0056b3;
}

/* Override for Breadcrumbs */
.breadcrumb-container {
  background-color: white !important; /* Ensure breadcrumbs container stays white */
}

/* Target the separator specifically to remove the grey background */
.breadcrumb-container .MuiBreadcrumbs-separator {
  background-color: white !important; /* Ensure the separator has no background */
  color: inherit; /* Ensure separator inherits the text color */
  font-weight: normal; /* Optional: make the separator font normal */
  padding: 0 10px !important;
  margin: 0;
}

/* Optional: If the individual breadcrumb items have unwanted grey backgrounds */
.breadcrumb-container .MuiBreadcrumbs-li {
  background-color: white !important; /* Force white background for each breadcrumb item */
}

/* Typography (last breadcrumb) white background */
.breadcrumb-container .MuiTypography-root {
  background-color: white !important;
  padding: 0 !important; /* Remove any extra padding */
  margin: 0 !important; /* Remove any extra margin */
  line-height: 1 !important; /* Align text perfectly */
  height: 100% !important; /* Prevent extra height */
  display: inline-block; /* Prevent unintended block spacing */
  vertical-align: middle; /* Align text vertically */
}
