.home-body {
  overflow: hidden;
}

.fax-container {
  margin-left: 5%;
  padding: 20px;
  flex: 1;
  background-color: white;
  min-height: 100vh;
}

.fax-title {
  color: #1976d2;
  font-size: 20px;
  letter-spacing: 1px;
}

.fax-input,
.description-input {
  width: 60%;
  padding: 10px;
  padding-right: 40px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
}

.proceed-button {
  background-color: #1976d2;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 13px;
}

.proceed-button:hover {
  background-color: #1565c0;
}

.error-message {
  color: red;
}

@media (max-width: 768px) {
  .fax-container {
    margin-left: 0;
    padding: 50px;
  }

  .fax-input,
  .description-input {
    width: 100%;
    padding-right: 30px;
  }

  .proceed-button {
    width: 30%;
    padding: 12px;
  }
}

.file-table {
  margin-top: 20px;
}

.file-table table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.file-table th {
  background-color: #f4f4f4;
  color: #1976d2;
}

table td:first-child,
table th:first-child {
  border-left: none;
}

table td:last-child,
table th:last-child {
  border-right: none;
}

.delete-button {
  background-color: #dc143c;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
}

.delete-button:hover {
  background-color: darkred;
}

@media (max-width: 480px) {
  .fax-input,
  .description-input {
    font-size: 14px;
  }

  .proceed-button {
    padding: 14px;
    font-size: 14px;
  }
}
