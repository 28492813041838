.home-body {
  overflow-y: auto !important;
  height: 95%;
  display: flex;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  background-color: white;
}

.email-container {
  width: 90%;
  margin-left: 5%;
  padding: 20px;
  flex: 1;
  background-color: white !important;
  /* min-height: 60vh; */
}

.input-group {
  margin-bottom: 20px;
  background-color: white;
}

.input-wrapper {
  position: relative;
  margin-bottom: 10px;
  background-color: white;
}
.email-form{
  background-color: white;
}

.subject-input,
.body-input {
  width: 100%;
  padding: 10px !important;
  padding-right: 40px;
  border: 1px solid black !important;
  border-radius: 5px;
  font-size: 11px !important;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  resize: none;
  background-color: white;
}

.email-icon,
.subject-icon {
  position: absolute;
  right: 430px;
  top: 50%;
  transform: translateY(-50%);
  color: #1976d2;
  font-size: 20px;
}

.proceed-button {
  padding: 10px;
  background-color: #0056b3;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  /* text-align: center; */
  display: block; 
  margin: 0 auto; 
  /* margin-left: 180px; */
}

.proceed-button:hover {
  background-color: #1565c0;
}

.file-table {
  margin-top: 20px;
  max-height: 200px;
  /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
}

.file-table.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: black;
  /* or any default color you prefer */
}

.file-table table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.file-table thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: #f4f4f4;
  color: black;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  font-size: 13px;
}

.file-table tbody td {
  background-color: white;
 
}

table td:first-child,
table th:first-child {
  border-left: none;
}

table td:last-child,
table th:last-child {
  border-right: none;
}

.delete-button {
  background-color: #dc143c;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 11px;
}

.delete-button:hover {
  background-color: darkred;
}

.file-message {
  color:red;
  padding-top: 15px;
  vertical-align: middle;
  padding:3px;
  margin-top: 10px;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.email-name {
  color: #1976d2;
  font-size: 20px;
  letter-spacing: 1px;
}

.email-error-message {
  margin-left: 2px;
  font-size: 13px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
}

.sucess-message {
  color: #1976d2;
}

@media (max-width: 768px) {
  .email-container {
    margin-left: 0;
    padding: 50px;
  }

  .email-input,
  .subject-input,
  .body-input {
    width: 100%;
    padding-right: 30px;
  }

  .email-icon,
  .subject-icon {
    font-size: 18px;
    right: 10px;
  }

  .proceed-button {
    width: 30%;
    padding: 12px;
    font-family: 'Montserrat', sans-serif;
  }
}

@media (max-width: 480px) {

  .email-input,
  .subject-input,
  .body-input {
    font-size: 14px;
  }

  .email-icon,
  .subject-icon {
    font-size: 16px;
  }

  .proceed-button {
    padding: 14px;
    font-size: 13px !important;
  }
  .name-field-email {
    max-width: 100px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
    background-color: white;
    font-size: 11px;
  }
  .file-name-tooltip{
    white-space: nowrap;
    max-width: none;
    font-size: 11px;
  }
}