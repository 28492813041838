.container * {
  background-color: white;
}

.dragActive {
  background-color: var(--light-blue-color);
}

.hover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-container {
}

.my-image {
  height: 300px;
  width: 300px;
}

.heading-container {
  font-size: 20px;
  font-weight: 400px;
}

.heading {
  margin-top: -80px;
  margin-left: -20px;
  font-size: 15px;
  padding: 10px 0px;
}

.subheading {
  margin-top: -45px;
  margin-left: -45px;
  font-size: 10px;
  font-weight: 200px;
}
