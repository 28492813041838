.feedback-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.feedback-overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 45%;
  min-height: 50%;
  max-height: 75%;
  position: relative;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.feedback-section {
  background-color: white;
}
textarea{
  margin: 0;
  padding: 0;
}
.feedback-textarea {
  width: 90%;
  height: 65px;
  padding: 3px;
  border-radius: 5px;
  border: 0.5px solid black;
  margin-left: 5%;
  margin-bottom: 10px;
  margin-top: 20px;
  margin-right: 0px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-size: 11px;
  font-weight: 400;
  font-style: normal;
  resize: none;
  background-color: white;
  display: flex;
}

.feedback-drop-zone {
  width: 90%;
  height: 65px;
  padding: 2px;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-top: 20px;
  margin-right: 0px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #1976d2;
}

.feedback-button-size{
  /* width:115px; */
  height:35px;
}

.feedback-submit-button-container{
  display: flex;
  justify-content: center;
  margin-left: 0px;
  margin-top: 20px;
  background-color: white;
}

.feedback-tab-container{
  width: 91.5%;
  margin-left: 5%;
  background-color: white;
}