.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 0 2px;
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.header-logo img {
  height: 57px;
  padding-top: 5px;
  cursor: pointer;
}

.header-search {
  flex-grow: 1;
  display: flex;
  justify-content: center;
}

.header-search input {
  width: 100%;
  max-width: 500px;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid black;

  font-size: 16px;
  background-color: #fafafa;
  transition: border-color 0.3s ease;
}

.header-search input:focus {
  border-color: #1e1e1e;
  border: 2px solid black;
  outline: none;
}

.header-icons {
  display: flex;

  margin-right: 50px;
}

.header-icons img {
  cursor: pointer;
  margin-right: 25px;
  margin-top: 3px;
  height: 27px;
}

.header-user {
  display: flex;
  align-items: center;
  gap: 15px;
  padding-left: 5px;
  cursor: pointer;
}

.header-user button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}

.header-user img {
  height: 35px;
}

.drawer-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;

  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 300;
  font-style: normal;
}

.drawer-email {
  font-size: 16px;
  margin-bottom: 50px;
  color: #333;
}

.drawer-profile-pic {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-bottom: -5px;
}

.drawer-username {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
}

.drawer-divider {
  width: 80%;
  border: none;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
}

.drawer-logout {
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #d9534f;
}

.drawer-logout-icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.drawer-logout-text {
  font-size: 16px;
}

.hamburger-menu {
  display: none;
  cursor: pointer;
  font-size: 24px;
  margin-right: 10px;
  margin-top: 9px;
}

.drawer-body {
  padding: 20px;
}

.drawer-body ul {
  list-style-type: none;
  padding: 0;
}

.drawer-body ul li {
  display: flex;
  align-items: center;
  padding: 10px 0 23px 0;
  border-bottom: 0.5px solid #ddd;
  cursor: pointer;
  color: #1976d2;
}

.drawer-body ul li:hover {
  background-color: #f0f0f0;
}

.drawer-body ul li .icon {
  margin-right: 10px;
}

@media (max-width: 400px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 2px 0 10px;
  }

  .header-icons {
    margin-left: 5px;
    margin-top: 14px;
    margin-right: 10px;
  }

  .header-logo {
    display: flex;
    align-items: center;
  }

  .header-logo img {
    margin-left: 10px;
    height: 50px;
  }
}

@media (max-width: 500px) {
  .header-logo {
    display: flex;
    align-items: center;
  }

  .header-logo img {
    margin-left: 10px;
  }
}

@media (max-width: 768px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 2px 0 10px;
  }

  .header-logo img {
    height: 50px;
  }

  .header-search {
    width: 50%;
    margin: 9px 2px;
  }

  .header-icons img {
    margin-top: 4px;
  }

  .header-user span {
    display: none;
    padding-top: 3px;
  }

  .header-logo {
    display: flex;
    align-items: center;
  }

  .header-logo img {
    margin-left: 10px;
  }

  .hamburger-menu {
    display: block;
  }
}

@media (max-width: 1000px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 2px 0 10px;
  }

  .header-logo img {
    height: 50px;
  }

  .header-search {
    width: 5%;
    margin: 9px 2px;
  }
  .header-search input {
    margin-left: 5px;
  }
  .header-icons {
    margin-left: 5px;
    margin-top: 14px;
    margin-right: 10px;
  }

  .header-icons img {
    margin-right: 10px;
    padding-left: 5px;
  }

  .header-user {
    margin-top: 15px;
    margin-right: 10px;
  }
}

@media (max-width: 1400px) {
  .header {
    flex-direction: row;
    align-items: flex-start;
    padding: 0 2px 0 10px;
  }

  .header-logo img {
    height: 50px;
  }

  .header-search {
    width: 5%;
    margin: 9px 2px;
  }

  .header-icons {
    margin-left: 5px;
    margin-top: 9px;
    margin-right: 10px;
  }

  .header-user {
    margin-top: 15px;
    margin-right: 10px;
  }
  .header-user span {
    margin-top: -9px;
  }

  .header-user img {
    margin-top: -5px;
  }
}
