.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* Ensure it's on top of other content */
}

.dialog-container {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

.dialog-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 15px;
  background-color: white;
}

.dialog-content {
  margin-bottom: 20px;
}

.dialog-input {
  width: 95%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.dialog-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  background-color: white;
}

.dialog-button {
  padding: 8px 12px;
  background-color: #ccc;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.dialog-button-confirm {
  background-color: #007bff;
  color: white;
}

.dialog-button:hover {
  background-color: #bbb;
}

.dialog-button-confirm:hover {
  background-color: #0056b3;
}
