.google-login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  background-color: white;
}

.or-text {
  font-size: 14px;
  color: #7d8597;
  background-color: white;
}

.google-login-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-top: 20px;
}

.google-login-button:hover {
  background-color: #f8f9fa;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.google-logo {
  width: 20px;
  margin-right: 10px;
}

.google-login-text {
  color: #1e1e1e;
  font-weight: 500;
}
