/* Add this to your CSS file or <style> block */
.button-style {
    margin-right: 5px;
    border-radius: 5px;
  }
  
  .button-style:hover {
    color: #1a73e8; 
  }

  .selected {
    background-color: blue;
    color: white;
  }