.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(244, 244, 244);
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(100%);
  transition: transformX(100%);
  z-index: 9999;
  border-radius: 12px;
}

.overlay.active {
  transform: translateX(0);

}

.overlay-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.close-btn {
  color: white;
  border: none;
  cursor: pointer;
  align-self: flex-end;
 
}

.full-screen-btn {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  cursor: pointer;
  align-self: flex-end;
}

.preview {
  flex-grow: 1;
  display: flex;
}

.leftArrow-icon-container,
.rightArrow-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex: 1;
  /* Equal width for the first and third columns */
  width: 100px;
  /* Fixed width */
}

.leftArrow,
.rightArrow {
  top: -50px;
  position: relative;
  width: 30px;
  height: 30px;
  border: none;
}

.previewContent {
  flex: 2;
  width: calc(100% - 200px);
  max-height: 85vh;
  /* Adjust to your container's height */
}


.overlay-icons {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-bottom: 20px;
  margin-right: -10px;
}

.close-btn,
.fullscreen-icon {
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: black;
  
}

.close-btn:hover {
  color: red;
}

.fullscreen-icon:hover {
  color: #0056b3;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 10;
  }
}

.HeaderContainer {
  padding-top: 10px;
  display: flex;
}

.HeaderTitle {
  padding-left: 20px;
  flex: 0 0 93%;
  color: rgb(52, 50, 47);
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  font-weight: 500;
}

.HeaderClose {
  flex: 0 0 5%;
  text-align: right;
}

.unSupportedFile {
  top: -50px;
  display: flex;
  justify-content: center;
  /* Centers horizontally, if needed */
  align-items: center;
  /* Centers vertically */
  height: 100vh;
  /* Full viewport height */
}

.downloadFileIcon {
  color: red;
  text-decoration: none;
  width: 35px;
  height: 35px;
  text-align: center;
  padding: 5px;
}

.downloadFileIcon:hover {
  color: white;
  text-decoration: underline;
}

.previewContent.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: black;
}

#previewLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure the parent has a height */
}