html,
body {
  height: 100%;
  overflow: hidden !important;
}

.resizable-sidebar {
  background-color: whitesmoke;
  position: relative;
}

.drag-handle {
  width: 1px;
  /* background-color: #e6e1d8; */
  background-color: #f4f4f4;
  cursor: ew-resize;
  position: absolute;
  top: -17px;
  right: -7px;
  height: 560px;
  border-radius: 5px 5px 5px 5px;
  transition: background-color 0.3s ease, width 0.3s ease;
}

.drag-handle:hover {
  /* background-color: #888; */
  width: 3px;
  background-color: #e6e1d8;
}

.drag-handle-dragging {
  background-color: #e6e1d8; /* Change color smoothly */
  width: 3px; /* Increase width smoothly */
}

.resizable-sidebar,
.drag-handle {
  user-select: none; /* Prevent text selection */
}

.home-body1 {
  display: grid;
  /* grid-template-columns: 200px auto; */
  /* padding: 20px; */
  padding: 20px 0px 20px 0px;
  height: calc(100vh - 60px);
  /* overflow-y: auto; */
  overflow-y: hidden;
  overflow-x: hidden;

  /* background-color: red; */
  min-width: 930px;
}

/* .container2 {
  margin-top: 10px;
  margin-left: 5px;
  height: 550px;
  display: flex;
  flex-direction: column;
} */

.container2 {
  margin-top: -30px;
  /* margin-left: 5px; */
  /* padding: 0 7px; */
  padding: 0 15px 0 7px;
  /* height: calc(100% - 50px); */
  height: 550px;
  display: flex;
  flex-direction: column;
  /* overflow-y: auto !important; */

  /* background-color: red; */
}

.container2-options {
  display: flex;
  align-items: center;
  margin-top: 10px;
  padding-bottom: 7px;
  padding-top: 3px;
  padding-left: 6px;
  background-color: white;
  border-radius: 5px 5px 0 0;
  /* border: 1px solid red; */
}

.container2-options2 {
  display: flex;
  align-items: center;
  margin-top: -15px;
  padding-left: 6px;
  background-color: white;
  /* border-radius: 5px 5px 0 0; */
  /* height: 50px; */
  /* border: 1px solid green; */
}

.container2-options-icon {
  display: flex;
  padding: 0px 10px 0 10px;
  width: auto;
  border-radius: 10px;
  gap: 3px;
  background-color: white;
  /* margin-bottom: -5px; */
}

.icon-wrapper {
  background-color: transparent;
  /* padding: 8px; */
  border-radius: 50%; /* Circular background */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* To allow absolute positioning of label */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.icon-wrapper.upload,
.icon-wrapper.folder,
.icon-wrapper.preview,
.icon-wrapper.download,
.icon-wrapper.email,
.icon-wrapper.rename,
.icon-wrapper.copy,
.icon-wrapper.cut,
.icon-wrapper.delete,
.icon-wrapper.fav,
.icon-wrapper.paste {
  padding: 8px;
}

.icon-wrapper.refresh {
  padding: 9px 5px 8px 8px;
}

.action-icon {
  /* color: #5f6368;  */
  color: var(--color-of-icons);
  background-color: white;
  transition: color 0.3s ease; /* Smooth hover transition */
  width: 21px !important;
}

.action-icon .cut {
  color: #5f6368; /* Neutral grey color similar to Google Drive */
  background-color: white;
  transition: color 0.3s ease; /* Smooth hover transition */
  width: 19px !important;
}

.action-icon.copy {
  width: 19px !important;
}

.action-icon .paste {
  width: 18px !important;
  color: #5f6368;
  background-color: white;
  transition: color 0.3s ease;
}

.action-icon.bookmarkremove {
  width: 33px !important;
}

.icon-wrapper:hover {
  /* background-color: #e8f0fe; */
  /* background-color: white; */
  cursor: pointer;
}

.icon-wrapper:hover .action-icon {
  color: #1a73e8; /* Blue color on hover */
  /* background-color: #e8f0fe; */
}

/* Tooltip / label style */
.icon-wrapper::after {
  content: attr(data-label); /* Use the data-label attribute as text */
  position: absolute;
  top: 35px; /* Adjust this to position the label below the icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease;
  z-index: 10;
}

.icon-wrapper:hover::after {
  opacity: 1;
  visibility: visible;
}

/* .container2-options svg {
  width: 25px;
} */

/* .container2-option span {
  margin-left: 5px;
  background-color: #4681f4;
  color: white;
  text-align: center;
  font-size: 11px;
} */
/* 
.container2-option svg {
  width: 13px;
  background-color: #4681f4;
  color: white;
  text-align: center;
} */

/* .container2-option.email svg {
  padding-left: 23px;
}
.container2-option.selectall svg {
  padding-left: 11px;
}
.container2-option.download svg {
  padding-left: 11px;
}
.container2-option.rename svg {
  padding-left: 15px;
} */

/* .container2-option {
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding: 7px 5px;
  width: 100px;
  height: 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #4681f4;
  text-align: center;
} */

/* .container2-options button {
  padding: 9px 16px;
  background-color: #0cabba;
  color: white;
  border: 0;
  border-radius: 5px;
  cursor: pointer;
} */

/* .container2-body {
  margin-top: 13px;
  border: 3px solid white;
  border-radius: 5px;
  height: 100%;
  background-color: white;
  padding-left: 7px;
  padding-right: 7px;
  max-width: 1250px;
} */

.container2-body {
  border: 3px solid white;
  border-radius: 0 0 5px 5px;
  flex-grow: 1;
  background-color: white;
  padding-left: 7px;
  padding-right: 7px;
  overflow-y: auto;
  scroll-behaviour: smooth;
  /* background-color: red; */
  margin-bottom: -25px;
}

/* TABLE COLOR  */

.details-Row {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* border-bottom: 1px solid #ccc; */
  /* border-bottom: 1px solid #f8f8ff; */
  /* border-bottom: 1px solid #f8efff; */
  /* border-bottom: 1px solid #f8eeff; */
  /* border-bottom: 1px solid red; */
  padding: 10px;
  cursor: pointer;

  font-family: "Montserrat", sans-serif;
}

.details-Row.title {
  position: sticky;
  top: 0;
  z-index: 5;
  height: 22px;
  border-top: 1px solid #f4f4f4;
}

.details-Row:not(.title):hover {
  background-color: #f4f4f4;
}

.details-Row p {
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 11px;
  /* width: 300px; */
  /* width: 250px; */
}
.details-Row.selected-file .name-field {
  background-color: #c2e7ff;
  /* background-color: red; */
}
.details-Row.selected-file:not(.title):hover .name-field {
  background-color: #c2e7ff;
  /* background-color: red; */
}
.details-Row:not(.title):hover .name-field {
  background-color: #f4f4f4;
  /* background-color: red; */
}
.details-Row.selected-file .location-field {
  background-color: #c2e7ff;
  /* background-color: red; */
}
.details-Row.selected-file:not(.title):hover .location-field {
  background-color: #c2e7ff;
  /* background-color: red; */
}
.details-Row:not(.title):hover .location-field {
  background-color: #f4f4f4;
  /* background-color: red; */
}

.details-Row .name-field-container {
  width: 38%;
  vertical-align: middle;
}
.details-Row .location-field-container {
  width: 33%;
  vertical-align: middle;
}
.details-Row .location-field {
  max-width: 50%;
  /* display: flex; */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  font-size: 11px;
}
.details-Row .name-field {
  max-width: 90%;
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
  background-color: white;
  font-size: 11px;
}

.bookMark-filled-icon {
  margin-right: 4px;
  vertical-align: middle;
}

.details-Row .created-date-field {
  width: 13%;
  vertical-align: middle;
}
.details-Row .size-field {
  width: 7%;
  vertical-align: middle;
}

/* .details-Row .location-field {
  width: 25%;
} */

.details-Row:not(.title):hover p {
  background-color: #f4f4f4;
}
.details-Row:not(.title):hover .file-type-icon {
  background-color: #f4f4f4;
}

.details-Row p b {
  background-color: white;
  display: flex;
  align-items: center;
  font-size: 13px;
  margin-left: 25px;
}

.details-Row:not(.title):hover svg {
  background-color: #f4f4f4;
}

.details-Row .size {
  margin-left: -11px;
}
.details-Row .re-date {
  margin-left: -17px;
}

.details-Row p b svg {
  background-color: white;
  width: 15px;
}
.details-Row p b {
  font-weight: 500;
}

.spinner-container {
  background-color: white;
}

.ring {
  background-color: white;
}

.context-menu {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  z-index: 1000;
  font-family: Arial, sans-serif;
  min-width: 150px;
  width: 230px;
  padding: 5px 0px;

  border-radius: 4px;
}

.context-menu li {
  list-style-type: none;
  padding: 5px 150px 5px 20px;
  font-size: 13px;
  font-weight: 500;
  color: #414a4c;
  cursor: pointer;
  background-color: white;
  margin: 5px 5px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  transition: background-color 0.1s ease;
}

.context-menu li span {
  display: flex;
  align-items: center;
  transition: background-color 0.1s ease;
}

.context-menu li svg {
  fill: black;
  width: 15px;
  background: white;
  padding-right: 7px;
  transition: background 0.1s ease;
}

.context-menu li:hover {
  background-color: var(--context-menu-bg-color-hover);
  color: black;
}

.context-menu li:hover svg {
  background: var(--context-menu-bg-color-hover);
}

.context-menu li:active {
  background-color: var(--context-menu-bg-color-hover);
  color: black;
}
.context-menu li svg:active {
  background-color: var(--context-menu-bg-color-hover);
  color: black;
}

.details-Row.selected-file {
  background-color: #c2e7ff !important;
  /* background-color: red !important; */
}

.details-Row.selected-file .file-type-icon {
  background-color: #c2e7ff;
}

.details-Row.selected-file p {
  background-color: #c2e7ff;
}

.details-Row.selected-file svg {
  background: #c2e7ff;
}

.details-Row.selected-file:hover {
  background-color: #c2e7ff;
}
.details-Row.selected-file:hover .file-type-icon {
  background-color: #c2e7ff;
}
.details-Row.selected-file:hover p {
  background-color: #c2e7ff;
}
.details-Row.selected-file:hover svg {
  background: #c2e7ff;
}

.drive-file-icon {
  margin-right: 8px;
  width: 15px;
  color: #0f67b1;
  background: white;
}

.folder-icon {
  margin-right: 8px;
  width: 17px;
  color: gray;
  background: white;
}

.my-hr {
  border: 1px solid rgb(229, 228, 226);
}

.details-Row input[type="checkbox"] {
  transform: scale(0.8); /* Make checkbox smaller */
  margin-right: 10px; /* Space between checkbox and name */
}

.container2-body::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

.container2-body::-webkit-scrollbar-thumb {
  background: gray; /* color */
  height: 3px;
  border-radius: 5px 5px 5px 5px; /* Round the corners of the scrollbar thumb */
}

.container2::-webkit-scrollbar-thumb:hover {
  background: #155a8a; /* Color of the scrollbar thumb on hover */
}

.file-type-icon {
  width: 30px;
  background-color: white;
}

.home-search {
  display: flex;
  align-items: center;
  width: 300px;
  background-color: rgb(234, 241, 251);
  padding: 10px 12px 10px 12px;
  border-radius: 30px;
  height: 13px;
}

.home-search input {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  background-color: transparent;
  border: 0;
  outline: 0;
  flex: 1;
}

.context-menu .highlighted-option {
  background-color: var(--context-menu-bg-color-hover);
  cursor: pointer;
  border-radius: 4px;
}

.context-menu .highlighted-option svg {
  background: var(--context-menu-bg-color-hover);
}

.no-scroll {
  overflow: hidden !important;
}

.drag-active {
  border-radius: 5px;
  border: 3px solid var(--border-blue-color);
  background-color: var(--light-blue-color);
}

.drag-active1 {
  background-color: var(--light-blue-color);
}

.details-Row.title.drag-active1 {
  border-bottom: 1px solid var(--light-blue-color);
}

.details-Row.drag-active3 {
  background-color: var(--light-blue-color);
  border-bottom: 1px solid var(--light-blue-color);
}
.details-Row.drag-active3 p {
  background-color: var(--light-blue-color);
}

.details-Row.drag-active3 svg {
  background: var(--light-blue-color);
}

.details-Row.drag-active3 .file-type-icon {
  background-color: var(--light-blue-color);
}

.location-click:hover {
  text-decoration: underline;
}

.other-heading {
  background-color: white;
  padding: 10px 10px 10px 13px;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  color: #007fff;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  letter-spacing: 1px;
}

/* ------------------------------------------ */

/* .details-Row .bookMark-icon-rightcolumn {
  visibility: hidden;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}
.details-Row:hover .bookMark-icon-rightcolumn {
  visibility: visible;
  opacity: 1;
  margin-right: 50px;
}

.bookMark-filled-icon-rightcolumn {
  visibility: hidden;
  transition: visibility 0.3s ease, opacity 0.3s ease;
  opacity: 0;
}

.details-Row:hover .bookMark-filled-icon-rightcolumn {
  visibility: visible;
  opacity: 1;
  margin-left: 30px;
}
.bookMark-rightcolumn {
  margin-left: 30px;
} */

/* --------------------------------------------------------- */

.spinner-container1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: transparent;
}

.skip-global-styles * {
  margin: unset !important;
  background-color: unset !important;
}

.item-name-tooltip {
  white-space: nowrap;
  max-width: none;
  font-size: 11px;
}

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
  .global-container {
    overflow-x: auto !important;
    scroll-behaviour: smooth;
    /* height: 700px; */
    height: 100%;
    width: 100% !important;
    overflow-y: hidden !important;
  }

  .global-container::-webkit-scrollbar {
    width: 1px !important;
    height: 10px;
  }

  .global-container::-webkit-scrollbar-thumb {
    background: gray;
    height: 1px;
    border-radius: 5px 5px 5px 5px;
  }

  .global-container::-webkit-scrollbar-thumb:hover {
    background: #155a8a;
  }
}

.trash-info-container {
  text-align: left;
  background-color: white;
  width: 55%;
  margin-left: auto;
  float: right;
}

.trash-info {
  background-color: white;
  color: #00008b;
  font-size: 13px;
  font-weight: 500;
  padding: 10px;
}

.overlayUploadDragDisabled {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.overlayUploadDragDisabledMessage {
  background: rgba(255, 255, 255, 0.9);
  /* Slightly transparent white background */
  padding: 20px 40px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  /* Cursor changes to pointer to indicate clickability */
}
