.sidebar {
  width: 150px !important;
  height: 100vh;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

.upload-button {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #7eb900;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  transition: background-color 0.2s ease;
}

.upload-button:hover {
  background-color: #4f81c7;
}

.menu-item {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  color: #333;
  border-radius: 5px;
  transition: background-color 0.2s ease;
}

.menu-item:hover {
  background-color: #f2f2f2;
}

.icon {
  margin-right: 10px;
  font-size: 15px;
}

span {
  font-size: 14px;
}
