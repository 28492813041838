.upload-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.upload-overlay-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 40%;
  /* min-height: 50%; */
  max-height: 75%;
  /* height: 530px; */
  position: relative;
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

.upload-overlay-content.dimmed {
  opacity: 0.5;
}

.upload-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  margin-right: 5px;
}

.spinner-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1002;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
}

.upload-close-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.content-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.content-top-name {
  background-color: white;
}

.content-top-close {
  background-color: white;
  cursor: pointer;
}

.content-top-name span {
  background-color: white;
  font-weight: 500;
  color: #007FFF;
  font-size: 15px;
  letter-spacing: 1px;
  font-family: Montserrat;
}

.content-top-close svg {
  background-color: white;
}

.upload-section {
  background-color: white;
}

.drop-zone {
  width: 91.5%;
  height: 65px;
  margin-bottom: 20px;
  margin-left: 5%;
  margin-top: 0px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #1976d2;
  margin-top: 20px;
  background-color: white;
  /* margin-left: 90px;
  width: 91.5%;
  height: 65px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #1976d2;
  margin-bottom: 20px;
  margin-top: 30px; */ 
}

.drop-zone-select {
  background-color: white;
  text-align: center;
}

.drop-zone-select button {
  padding: 7px 20px;
  border-radius: 5px;
  background-color: #4681f4;
  cursor: pointer;
  border: 0;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  margin-top: 10px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  z-index: 100;
}

.spinner-container {
  background-color: transparent;
  width: 100%;
}

.tab-container {
  margin-top: 10px;
  background-color: white;
}

table {
  width: 100%;
  border-collapse: collapse;
}

td {
  padding: 8px;
  vertical-align: middle;
}

th {
  text-align: left;
  background-color: whitesmoke;
  font-weight: 500;
  border-bottom: 2px solid #ddd;
  color: #4681f4;
}
.tab-container thead svg {
  fill: #ff5c5c;
  width: 20px;
  cursor: pointer;
}

.table-body .close {
  width: 20px;
  cursor: pointer;
}

.table-body .close:hover {
  fill: #ff5c5c;
}

td {
  border-bottom: 1px solid #ddd;
  vertical-align: middle;
}

td:first-child {
  text-align: center;
  width: 5%;
}

.file-name-wrapper {
  display: flex;
  align-items: center;
  background-color: white;
}

.file-name {
  font-size: 14px;
  margin-right: 8px;
  cursor: pointer;
}

.edit-icon {
  cursor: pointer;
  font-size: 16px;
}

td:last-child {
  text-align: right;
  width: 18%;
}

.tab-container tbody td {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
}

.tab-container tbody td svg {
  background-color: white;
}

.tab-container tbody td span {
  background-color: white;
  font-family: "Montserrat", sans-serif;
  font-size: 11px;
  font-weight: 400;
}

.tab-container thead th {
  text-align: left;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  margin-left: 10px;
  margin-top: 20px;
  background-color: white;
}

.submit-button {
  padding: 10px 20px;
  background-color: #4681f4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
}

.message {
  color: #ff5c5c;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 500;
}

.upload-section .drop-zone span {
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px;
  font-weight: 400;
}

.file-table {
  margin-top: 20px;
  max-height: 200px;
  /* Adjust height as needed */
  overflow-y: auto;
  border: 1px solid #ccc;
}

.file-table.custom-scrollbar ::-webkit-scrollbar-thumb {
  background: black;
  /* or any default color you prefer */
}

.file-table table {
  width: 100%;
  border-collapse: collapse;
}

.file-table th,
.file-table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.file-table thead th {
  position: sticky;
  top: -1px;
  z-index: 1;
  background-color: #f4f4f4;
  color: #1976d2;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}

.file-table tbody td {
  background-color: white;
}

table td:first-child,
table th:first-child {
  border-left: none;
}

table td:last-child,
table th:last-child {
  border-right: none;
}

.delete-button {
  background-color: #dc143c;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 3px;
}

.delete-button:hover {
  background-color: darkred;
}