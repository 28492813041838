.business-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 555;
  animation: fadeIn 0.3s ease-out;
}

.business-overlay-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;

  min-height: 430px;
  min-width: 750px;
}

.business-title {
  padding: 0 0 20px 20px;
  letter-spacing: 1px;
  background-color: white;
  font-size: 15px;
}

.business-overall-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  background-color: white;
}

.left-container {
  width: 30%;
  padding-right: 20px;
  padding-left: 20px;
  background-color: white;
}

.right-container {
  width: 70%;
  padding: 0 50px;
  background-color: white;
}

.left-container p {
  padding: 10px;
  margin: 0;
  cursor: pointer;
  position: relative;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1px;
  background-color: white;
}

.left-container p::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px; /* Thickness of the line */
  height: 15px; /* Height of the line */
  background-color: transparent;
  transition: all 0.3s ease;

  border-radius: 5px;
}

.left-container p.tab-selected::before {
  background-color: var(--tab-color); /* Line color when selected */
}
.left-container p.tab-selected {
  color: var(--tab-color); /* Text color when selected */
}

.vertical-line {
  width: 1px;
  background-color: lightgrey;
  height: 350px;
  position: absolute;
  left: 40%;

  transform: translateX(-50%);
  border-radius: 5px;

  opacity: 0; /* Initially invisible */
}

/* PROFILE SELECT */

.profile-container {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  background-color: white;
}

.business-profile-logo {
  width: 100px;
  height: 100px;
  border-radius: 5px;
  object-fit: cover;
  border: 1px solid #ddd;
}

.business-details {
  text-align: left;
  background-color: white;
}

.business-detail-item {
  display: grid;
  grid-template-columns: 205px 1fr; /* 150px for the key, remaining space for the value */
  margin: 10px 0;
  font-size: 16px;
  color: #333;
  align-items: flex-start;
  padding-bottom: 7px;
  background-color: white;
}

.key {
  font-weight: 700;
  color: #555;
  padding-right: 5px; /* Reduced space between key and value */
  white-space: nowrap; /* Prevent text wrapping */
  background-color: white;
  font-size: 13px;
}

.value {
  word-wrap: break-word; /* Allow text to wrap if it’s too long */
  white-space: pre-line; /* Preserve line breaks in the value */
  background-color: white;
  font-size: 13px;
  padding-left: 5px;
}

/* PROFILE SELECT */

/* Update Info */

.business-detail-item-for-update-info {
  display: grid;
  grid-template-columns: 205px 1fr; /* 150px for the key, remaining space for the value */
  margin: 20px 0;

  font-size: 16px;
  color: #333;
  align-items: flex-start;
  padding-bottom: 10px;
  background-color: white;
}

.business-value-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;
}

.edit-icon {
  cursor: pointer;
  margin-left: 10px;
  width: 15px !important;
  background: white;
}

.save-btn {
  margin-top: 20px;
  padding: 10px 30px;
  letter-spacing: 1px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Update Info */
.input-field {
  width: 100%; /* Full width */
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 1px solid #007bff !important;
  outline: none; /* Remove default focus outline */
  font-size: 12px !important;
  padding: 5px 0;
  /* transition: border-color 0.5s ease; */
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  background: none;
  outline: none;
}

.input-field:focus {
  border-bottom: 2px solid #007bff !important;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 1px;
  box-shadow: 0px 2px 5px rgba(0, 123, 255, 0.2); /* Add a subtle shadow */
}

.custom-textarea {
  width: 100%; /* Make it responsive */
  height: 100px;
  outline: none;
  font-size: 12px !important;
  padding: 10px;
  background-color: transparent;
  line-height: 1.6;
  resize: none;
  transition: border-color 0.5s ease;
  color: #333; /* Text color */
  font-size: 15px;
  border: 1px solid #007bff !important;
}

.custom-textarea:focus {
  border: 2px solid #007bff !important;
  border-radius: 5px;
  padding: 10px;
  height: 100px;
}
