.header1-search {
  display: flex;
  align-items: center;
  width: 500px;
  background-color: white;
  padding: 10px 12px 10px 12px;
  border-radius: 30px;
  height: 13px;
  margin-left: -113px;
  position: relative;
}

.header1-search svg {
  background-color: white;
}

.header1-search input {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  background-color: transparent;
  border: 0;
  outline: 0;
  flex: 1;
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  z-index: 1000;
  max-height: 150px;
  overflow-y: auto;
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.suggestions-list::-webkit-scrollbar {
  width: 8px;
}
.suggestions-list::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.suggestions-list::-webkit-scrollbar-thumb {
  background: #888;
}
.suggestions-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
  background-color: white;
  font-size: 12px;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
  font-size: 12px;
}

suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li.highlighted {
  background-color: #ddd;
} 

  /* .suggestions-list li {
    padding: 8px;
    cursor: pointer;
  }
  
  .suggestions-list li.highlighted {
    background-color: #ddd;
  } */ 

/* .suggestions-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  position: absolute;
  background-color: white;
  z-index: 1;
}

.suggestions-list li {
  padding: 8px;
  cursor: pointer;
}

.suggestions-list li.highlighted {
  background-color: #ddd;
}  */

 .highlighted {
  background-color: #ddd;
} 