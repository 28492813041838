.folder-header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px;
}

.folder-header.selected {
  background-color: lightblue;
}

.folder-tree1 {
  /* background-color: red; */
  height: 370px;
  overflow-x: auto;
  padding-right: 3px;
}

.folder-tree1::-webkit-scrollbar {
  width: 3px; /* Width of the scrollbar */
}

.folder-tree1::-webkit-scrollbar-thumb {
  background: gray; /* color */
  height: 3px;
  border-radius: 5px 5px 5px 5px; /* Round the corners of the scrollbar thumb */
}

.folder-tree1::-webkit-scrollbar-thumb:hover {
  background: var(--scroll-bar-hover-color);
}

.folder-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  margin-left: 0;
}

.folder-children {
  margin-left: 0;
}

/* Ensure that the arrow and folder icons stay together */
.folder-header > span {
  display: flex;
  align-items: center;
  gap: 5px; /* Add some space between the arrow and folder icon */
}

.sidebar1-option {
  display: flex;
  align-items: center;

  padding: 3px 20px;
  border-radius: 20px;
  margin-bottom: 5px;
  transition: background-color 0.3s ease;
  width: calc(100% - 40px);

  background-color: whitesmoke;
  /* background-color: red; */
}

.sidebar1-option span {
  transition: background-color 0.3s ease;

  background-color: whitesmoke;
}

.sidebar1-option svg {
  transition: background-color 0.3s ease;

  background-color: whitesmoke;
  width: 20px;
}

.sidebar1-option:hover {
  background-color: lightgray;
  cursor: pointer;
}

.sidebar1-option:hover svg {
  background-color: lightgray;
}

.sidebar1-option:hover span {
  background-color: lightgray;
}

.sidebar1-option span {
  /* margin-left: 15px; */
  font-size: 13px;
  font-weight: 500;
  color: rgb(78, 78, 78);
}

.sidebar1-option.history span {
  margin-left: 8px;
}
.sidebar1-option.recent-file span {
  margin-left: 8px;
}

.sidebar1-option .sidebar1-home {
  margin-left: 8px;
}

.sidebar1-option .sidebar1-recent {
  margin-left: 8px;
}

.sidebar1-option .sidebar1-favorites {
  margin-left: 8px;
}

.sidebar1-option .sidebar1-trash {
  margin-left: 8px;
}

.sidebar1 {
  margin-top: -15px;

  /* TABLE COLOR  */
  /* border-bottom: 1px solid #ccc; */
  /* border-bottom: 1px solid #f8f8ff; */
  /* border-bottom: 1px solid #f8efff; */
  /* border-bottom: 1px solid #f8eeff; */

  background-color: whitesmoke;
  /* background-color: #fffff8; */
  padding: 10px;

  border-radius: 10px;

  /* height: 100vh; */
}

.sidebar1-btn button {
  background-color: transparent;
  border: 1px solid lightgray;
  display: flex;
  align-items: center;
  border-radius: 5px;
  /* padding: 9px 25px; */
  padding: 5px 40px;
  margin-left: 20px;
  margin-bottom: 15px;
  margin-top: -15px;
  background-color: #83f28f;
  cursor: pointer;
  height: 36px;

  transition: background-color 0.3s ease;
}

.sidebar1-btn {
  background-color: whitesmoke;
}

.sidebar1-btn button span {
  margin-left: 5px;
  font-size: 13px;
}

.sidebar1-btn button svg {
  background-color: #83f28f;
  transition: background-color 0.3s ease;
  width: 16px;
}

.sidebar1-btn button span {
  background-color: #83f28f;
  transition: background-color 0.3s ease;
}

.sidebar1-btn:hover button {
  background-color: #39e75f;
}

.sidebar1-btn:hover button span {
  background-color: #39e75f;
}

.sidebar1-btn:hover button svg {
  background-color: #39e75f;
}

.sidebar1-options {
  margin-top: 10px;
  background-color: whitesmoke;
}

.selected {
  background-color: #add8e6;
  color: black;
  transition: background-color 0.3s ease;
}

.selected svg {
  background-color: #add8e6;
  color: black;
}

.selected span {
  background-color: #add8e6;
}

.selected:hover {
  background-color: #add8e6;
}

.selected:hover svg {
  background-color: #add8e6;
}

.selected:hover span {
  background-color: #add8e6;
}

.folder-name.long-name {
  position: relative;
}

.folder-name.long-name:hover::after {
  content: attr(title);
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  white-space: nowrap;
  top: 100%;
  left: 0;
  transform: translateY(5px);
  z-index: 10;
  font-size: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.folder-children {
  margin-left: 0;
}

/* .folder-header.selected {
  background-color: lightblue;
} */

.popup-menu {
  position: absolute;
  /* top: 80px; */
  /* left: 180px; */
  top: 10px;
  left: 160px;
  background-color: white;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  /* width: 150px; */
  z-index: 10;

  font-family: Arial, sans-serif;
  min-width: 200px;
  padding: 5px 0px;
}

.popup-menu ul {
  list-style: none;
  margin: 0 5px;
  padding: 0;
  background-color: white;
}

.popup-menu ul li {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 5px 50px 5px 20px;

  font-size: 13px;
  color: black;
  background-color: white;
  border-radius: 5px;
  transition: background-color 0.1s ease;
}

.popup-menu ul li:hover {
  background-color: var(--context-menu-bg-color-hover);
}

.popup-menu ul li:hover svg {
  background-color: var(--context-menu-bg-color-hover);
}

.popup-menu ul li svg {
  width: 15px;
  background-color: white;
}
