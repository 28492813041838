.show-ad {
  width: 100%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--black-color);
  font-size: 10px;
  letter-spacing: 1px;
  font-weight: 400;
  color: white;
}

.show-ad a {
  text-decoration: none;
  margin-left: 10px;
  border-bottom: 2px solid transparent;
  transition: border-bottom 0.3s;
  color: #007bff;
  font-size: 15px;
  font-weight: 500;
  background: #afdbf5;
}

.show-ad a:hover {
  border-bottom: 2px solid #007bff;
}

.plans-heading {
  font-size: 16px !important;
  font-weight: bold;
}

.logotitle {
  background: white;
  margin-left: 15px;
  color: black;
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 1px;
}

.landing-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.landing-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  background: var(--white-color);
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  transition: background 0.3s, color 0.3s;

  border-bottom: 1px solid lightgrey;
}

.landing-header.scrolled {
  background: white !important;
  color: black !important;
}

.app-logo {
  height: 50px;
  width: 50px;
  background: white;
  border-radius: 5px;
}

.landing-nav-bar {
  margin-right: 49px;
  /* background: var(--black-color); */
  background: var(--white-color);
}

.landing-nav-bar a {
  font-size: 13px;
  font-weight: 500;
  color: var(--black-color);
  margin-left: 40px;
  text-decoration: none;
  position: relative;
  /* background: var(--black-color); */
  background: var(--white-color);
}

.landing-nav-bar a::before {
  content: "";
  position: absolute;
  width: 0;
  top: 100%;
  left: 0;
  height: 2px;
  background: currentColor;
  transition: width 0.3s;
}

.landing-nav-bar a:hover::before {
  width: 100%;
}

.landing-nav-bar .login {
  padding: 8px 25px;
  margin-left: 25px;
  cursor: pointer;
  letter-spacing: 1px;
  background-color: #007bff;
  border: none;
  color: white;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.landing-nav-bar .login:hover {
  background-color: #0056b3;
}

.scrollable-content {
  margin-top: 88px; /* Offset header */
  flex: 1;
  overflow-y: auto; /* Enable vertical scrolling */
  /* padding-bottom: 20px; Ensure no cut-off content */
  /* height: calc(100vh - 90px);  */
  overflow-x: hidden;
}

.main-content {
  height: 300px;
  background: white;
  color: white;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  position: relative;
}

.main-content img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;

  filter: brightness(60%);
}

.main-content span {
  position: absolute; /* Makes the text overlay the video */
  color: white;
  font-size: 25px; /* Adjust for desktop view */
  text-align: center;
  line-height: 1.5;
  z-index: 1; /* Ensure text is above the video */
  background: transparent;
}

.main-content span div {
  background: transparent;
  display: inline-block; /* Default for desktop */
  padding-right: 15px;

  font-size: 30px;
  font-weight: 500;
}

.main-content h1 {
  background: var(--black-color);
  padding: 200px 200px;
  text-align: center;
}

.container {
  background-color: white;
  padding: 50px 20px;
}

.container h2 {
  text-align: center;
  font-size: 2rem;
  margin-bottom: 40px;
  color: #333;
}

.price-row {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
  grid-template-columns: repeat(3, 200px);
  gap: 20px;
  margin: 0 auto;
  max-width: 1200px;
  justify-content: center;
}

.price-col {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  width: 150px;
}

.price-col:hover {
  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.plan-title {
  font-size: 9px;
  font-weight: bold;
  margin-bottom: 15px;
  color: var(--black-color);
  text-align: left;
}

.price-col h3 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: -8px;

  margin-bottom: 15px;
  color: #333;

  text-align: left;
}

.monthly-yearly {
  font-size: 10px !important;
}

.price-col h3 span {
  font-size: 1rem;
  color: #666;
}

.price-col ul {
  list-style: none;
  padding: 0;
  margin: 18px 0;
  color: #555;
}

.price-col ul li {
  margin: 10px 0;
  font-size: 9px;
  text-align: left;
}

.price-col button {
  /* color: #007bff; */
  color: black;
  /* border: 2px solid #007bff; */
  border: 1px solid grey;
  border-radius: 5px;
  padding: 10px 15px;
  font-size: 9px;
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;

  font-weight: 600;
  letter-spacing: 1px;
}

.price-col button:hover {
  background-color: #0056b3;
}

.price-col:hover {
  border: 2px solid #007bff;
}

.price-col button:hover {
  color: white;
}

.price-col:hover .plan-title {
  /* color: #007bff; */
}

.price-col:hover button {
  background-color: #0056b3;
  color: white;
}

.empty-container {
  background-color: white;
  height: 300px;
}

/* FOOTER */
.footer {
  /* background-color: var(--black-color); */
  background-color: var(--white-color);
  padding: 40px 20px;
  color: #333;
}

.footer * {
  /* background-color: var(--black-color); */
  background-color: var(--white-color);
}

.footer .line {
  border-top: 1px solid lightgrey;
  padding: 30px;
}

.footer-container {
  max-width: 1200px;
  margin: 0 auto;
  /* background-color: var(--black-color); */
  background-color: var(--black-color);
}

.footer-row {
  display: flex;
  justify-content: center; /* Center the columns */
  /* gap: 10px; */
  text-align: left;
  flex-wrap: nowrap; /* Prevent columns from wrapping to the next row */
  padding-left: 100px;
}

.footer-col {
  /* flex: 1 1 1 20%; Default: each column takes 30% width */
  min-width: 250px; /* Prevents columns from becoming too small */
  padding: 0 20px;
}

.footer-col h4 {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 15px;
  color: var(--black-color);
}

.footer-col ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-col ul li {
  margin-bottom: 10px;
}

.footer-col a {
  color: grey;
  text-decoration: none;
  font-size: 10px;
  transition: color 0.3s ease;
}

.footer-col a:hover {
  color: #007bff;
}

.footer-bottom p {
  font-size: 8px;
}

/* Footer bottom (for copyright) */
.footer-bottom {
  /* background-color: var(--black-color); */
  background-color: var(--white-color);
  color: var(--black-color);
  text-align: center;
  padding: 50px 20px 20px 20px;
  font-size: 0.9rem;
  margin-top: 20px;
}

/* TOOGLE  */

.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;

  background-color: white;
}

.toggle-container span {
  font-size: 12px;
  font-weight: bold;
  margin: 0 10px;
  color: #666;
  transition: color 0.3s;
  background-color: white;
}

.toggle-container span.active {
  color: #007bff;
}

.toggle-switch {
  position: relative;
  width: 35px;
  height: 17px;
  background-color: #ddd;
  border-radius: 25px;
  cursor: pointer;
  margin: 0 3px;
  transition: background-color 0.3s;
}

.toggle-switch:hover {
  background-color: #ccc;
}

.toggle-knob {
  position: absolute;
  top: 2.5px;
  left: 2px;
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
}

.toggle-knob.annual {
  transform: translateX(18px);
}

/* SCROLL BAR */

.scrollable-content::-webkit-scrollbar {
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background: grey;
  height: 3px;
  border-radius: 5px 5px 5px 5px;
}

.scrollable-content::-webkit-scrollbar-thumb:hover {
  background: #155a8a;
}

/* CHECKOUT PAGE */

.checkout-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  animation: fadeIn 0.3s ease-out;
}

.checkout-overlay-content {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.3s ease-out;
}

.checkout-overlay-content h2 {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
  letter-spacing: 1px;

  background: var(--white-color);
}

.checkout-overlay-content h3 {
  font-size: 15px;
  letter-spacing: 1px;
  font-weight: 500;
  background: var(--white-color);
}

.checkout-container {
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: var(--white-color);
}

.confirm-button {
  display: flex;
  /* justify-content: space-between; */
  justify-content: center;
  align-items: center;

  background: var(--white-color);
}

.confirm-button button {
  padding: 10px 20px;
  border-radius: 5px;
  background: #007bff;
  color: white;
  border: none;
  letter-spacing: 1px;
  cursor: pointer;
  font-weight: 500;
  transition: background 0.3s;
}

.confirm-button button:hover {
  color: white;
  background: #0056b3;
}

.confirm-button .cancel-button {
  background-color: #fd5c63 !important;
}

.price-col-checkout {
  background: var(--white-color);
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 150px;

  transform: translateY(-10px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  border: 2px solid #007bff;
}

.plan-title-checkout {
  font-size: 1px;
  font-weight: bold;
  margin-bottom: 15px;
  /* color: #007bff; */
  color: var(--black-color);

  text-align: left;
}

.price-col-checkout h3 {
  font-size: 15px;
  font-weight: bold;
  margin-top: -8px;

  margin-bottom: 15px;
  color: #333;

  text-align: left;
}

.price-col-checkout p {
  font-size: 10px;
  color: #666;

  background: var(--white-color);
}

.price-col-checkout p strong {
  background: var(--white-color);
}

.price-col-checkout ul {
  list-style: none;
  padding: 0;
  margin: 18px 0;
  color: #555;

  background: var(--white-color);
}

.price-col-checkout ul li {
  margin: 10px 0;
  font-size: 9px;
  text-align: left;

  background: var(--white-color);
}

/* Checkout Summary Styling */

.total-bill {
  border-top: 1px solid lightgrey;
  padding-top: 2px;
  background: var(--white-color);
}

.checkout-summary {
  width: 45%;
  padding: 20px;
  background: var(--white-color);
  display: flex;
  flex-direction: column;
}

.checkout-summary p {
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--white-color);
}

.checkout-summary p:last-child {
  margin-bottom: 0;
}

.checkout-summary strong {
  font-weight: bold;
  color: #333;
  background: var(--white-color);
}

.checkout-summary p span.label {
  width: 150px;
  text-align: left;
  font-size: 11px;
  letter-spacing: 1px;

  background: var(--white-color);
}

.checkout-summary p span.value {
  text-align: left;
  flex-grow: 1;
  font-size: 10px;
  letter-spacing: 1px;
  background: var(--white-color);
}

/* Animations */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

/*  MEDIA QUERY */

/* Responsive design for smaller screens */
@media (max-width: 1024px) {
  .show-ad {
    font-size: 11px;
    padding: 5px;
  }

  .show-ad a {
    font-size: 11px;
  }

  .logotitle {
    font-size: 19px;
    margin-left: 8px;
  }

  .logo-container-landing-page {
    margin-left: -8px;
  }

  .landing-nav-bar {
    margin-right: 40px;
  }

  .landing-header {
    /* background-color: red; */
    margin-top: -5px;
  }

  .footer-row {
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-left: 0px;
  }

  .footer-col {
    text-align: center;
  }

  .footer-col.one {
    margin-bottom: 30px;
  }

  .footer-col.two {
    margin-bottom: 30px;
  }

  .footer-col h4 {
    font-size: 1.1rem;
  }

  .main-content span div {
    /* display: block; */
    padding-bottom: 10px;

    font-size: 20px;
    font-weight: 400;
  }

  .price-col {
    width: 91%;
  }

  .price-row {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    grid-template-columns: repeat(3, 200px);
    gap: 40px;
    margin: 0 auto;
    max-width: 1200px;
    justify-content: center;
  }

  .toggle-container .leading-square {
    /* margin-left: 20px; */
  }

  .scrollable-content {
    margin-top: 83px;
  }
}

/* Responsive design for smaller screens */
@media (max-width: 900px) {
  .show-ad {
    font-size: 11px;
    padding: 5px;
  }

  .show-ad a {
    font-size: 11px;
  }

  .logotitle {
    font-size: 19px;
    margin-left: 8px;
  }

  .logo-container-landing-page {
    margin-left: -8px;
  }

  .landing-nav-bar {
    margin-right: 40px;
  }

  .landing-header {
    /* background-color: red; */
    margin-top: -5px;
  }

  .footer-row {
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-left: 0px;
  }

  .footer-col {
    text-align: center;
  }

  .footer-col.one {
    margin-bottom: 30px;
  }

  .footer-col.two {
    margin-bottom: 30px;
  }

  .footer-col h4 {
    font-size: 1.1rem;
  }

  .main-content span div {
    /* display: block; */
    padding-bottom: 10px;

    font-size: 20px;
    font-weight: 400;
    /* color: red; */
  }

  .price-col {
    width: 81%;
  }

  .price-row {
    display: grid;
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    grid-template-columns: repeat(3, 200px);
    gap: 40px;
    margin: 0 auto;
    max-width: 1200px;
    justify-content: center;
  }

  .toggle-container .leading-square {
    /* margin-left: 20px; */
  }

  .scrollable-content {
    margin-top: 83px;
  }
}

@media (max-width: 700px) {
  .show-ad {
    font-size: 11px;
    padding: 5px;
  }

  .show-ad a {
    font-size: 11px;
  }

  .logotitle {
    font-size: 19px;
    margin-left: 8px;
  }

  .logo-container-landing-page {
    margin-left: -8px;
  }

  .landing-nav-bar {
    margin-right: 40px;
  }

  .landing-header {
    /* background-color: red; */
    margin-top: -5px;
  }

  .footer-row {
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    padding-left: 0px;
  }

  .footer-col {
    text-align: center;
  }

  .footer-col.one {
    margin-bottom: 30px;
  }

  .footer-col.two {
    margin-bottom: 30px;
  }

  .footer-col h4 {
    font-size: 1.1rem;
  }

  .main-content {
    height: 400px;
    background: white;
    color: white;
    padding: 20px 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }

  .main-content video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .main-content span {
    font-size: 20px; /* Reduce font size */
  }

  .main-content span div {
    display: block; /* Stack words vertically */
    padding-bottom: 10px;
    /* text-align: left; */
    font-size: 20px;
    font-weight: 400;

    /* color: red; */
  }

  .price-col {
    width: 81%;
  }

  .price-row {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    /* margin-left: 10px; */
    justify-content: center;
    width: 200px;
    /* margin: 0 50px; */
  }

  .toggle-container .leading-square {
    margin-left: 20px;
  }

  .scrollable-content {
    margin-top: 83px;
  }
}

@media (max-width: 500px) {
  .price-col-checkout {
    display: none;
  }

  .checkout-summary {
    width: 100% !important;
    padding: 50px !important;
  }

  .checkout-summary .value {
    font-size: 10px !important;
    width: 50%;
  }

  .overlay-content {
    padding: 20px !important;
  }

  .overlay-content h2 {
    font-size: 15px !important;
  }
  .overlay-content h3 {
    font-size: 13px !important;
    text-align: center;
  }

  .confirm-button button {
    font-size: 12px;
  }
}
