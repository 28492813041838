.email-tag-input {
  background-color: white !important;
  border: none !important;
  outline: none;
  padding: 5px;
  font-size: 16px;
  width: 97%;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-size: 11px !important;
}

.email-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  /* width: 69.5%; */
  padding: 3px 0px 0px 3px;
  font-size: 13px;
}

.email-tag {
  padding: 5px 10px;
  border-radius: 3px;
  border: 1px solid gray;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.email-tag svg {
  margin-left: 5px;
  cursor: pointer;
}

.email-error-message {
  color: red;
  background-color: white;
}
