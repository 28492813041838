.common-overlay  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .common-heading{
    color: #007FFF;
    font-size: 15px;
    background-color: white;
    font-weight: 500;
    letter-spacing: 1px;
    font-family: "Montserrat", sans-serif;
  }
  
  .email-overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 45%;
    /* min-height: 10%;
    max-height: 75%; */
    position: relative;
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }

  .feedback-overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 45%;
    /* min-height: 60%; */
    max-height: 80%;
    position: relative;
    transition: background-color 0.3s ease, opacity 0.3s ease;
    /* margin-left: 5%; */
  }

  .upload-overlay-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 45%;
    min-height: 50%;
    max-height: 75%;
    position: relative;
    transition: background-color 0.3s ease, opacity 0.3s ease;
  }

  .preview-overlay-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  
  .common-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    /* font-size: 15px; */
    cursor: pointer;
  }
  