.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-width: 300px;
  max-width: 400px;
  height: 200px;
  /* text-align: center; */
}

.modal-content h3 {
  margin: 0 0 15px;
  font-size: 15px;
  background-color: white;
}

.modal-content input {
  padding: 10px;
  width: 90%;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-bottom: 15px;
  font-size: 15px;
  background-color: white;
}

.modal-buttons {
  display: flex;
  justify-content: flex-end;
  background-color: white;
}

.cancel-button,
.ok-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 15px;
  cursor: pointer;
  margin-top: 25px;
}

.cancel-button {
  background-color: #e0e0e0;
  color: #000;
  margin-right: 10px;
}

.ok-button {
  background-color: #007bff;
  color: #fff;
}

.cancel-button:hover {
  background-color: #d0d0d0;
}

.ok-button:hover {
  background-color: #0056b3;
}
.error-msg{
  color: red;
  font-size: 13px;
  margin-top: 3px;
  background-color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
  margin-left: 5px;
}