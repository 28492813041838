.header1 {
  display: grid;
  grid-template-columns: 300px auto 200px;
  align-items: center;
  /* padding: 5px 20px; */
  margin-top: -1px;
  padding: 0px 20px 5px 20px;
  height: 60px;
  /* border-bottom: 1px solid lightgray; */
  border-bottom: 1px solid #f8eeff;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  /* border: 1px solid red; */

  /* background-color: red; */
}

.header1-logo {
  display: flex;
  align-items: center;
  margin-left: -7px;
  /* border: 1px solid red; */

  /* background-color: red; */
}

/* .search {
  display: flex;
  align-items: center; */
  /* border: 1px solid red; */

  /* background-color: red; */
  
/* } */

.company-logo {
  border-radius: 5px;
  background-color: #87cefa;
}

.header1-logo img {
  width: 40px;
  cursor: pointer;
  margin-left: -3px;
}

.header1-logo span {
  font-size: 18px;
  margin-left: 6px;
  color: gray;
  font-weight: 500;

  /* background-color: red; */
}

.header1-icons {
  display: flex;
  align-items: center;
  /* border: 1px solid red; */

  /* background-color: red; */
}

.header1-icons img {
  width: 20px;
  cursor: pointer;
}

.header1-icons span {
  display: flex;
  align-items: center;
   margin-left: 10px;
}

.header1-icons .left-icon {
  margin-left: 15px;
}

/* Drawer Styling */

.custom-drawer {
  position: fixed;
  top: 75px;
  margin-right: 15px;
  right: -400px;
  width: 300px;
  height: 50%;
  /* height: 100%; */
  /* background-color: whitesmoke; */
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.4s ease;
  z-index: 1100;
  border-radius: 5px;
  overflow-y: auto;
  padding: 15px 20px;
}

.custom-drawer::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

.custom-drawer::-webkit-scrollbar-track {
  background: white; /* Background of the scrollbar track */
}

.custom-drawer::-webkit-scrollbar-thumb {
  background: #2f4f4f; /* color */

  border-radius: 0px 5px 5px 0px; /* Round the corners of the scrollbar thumb */
}

.custom-drawer::-webkit-scrollbar-thumb:hover {
  background: #155a8a; /* Color of the scrollbar thumb on hover */
}

.custom-drawer.open {
  right: 0;
}

.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  z-index: 1000;
  transition: opacity 0.3s ease;
}

/* NEW STYLING  */

.drawer-content1 {
  display: flex;
  align-items: center;
  /* background-color: whitesmoke; */
  background-color: white;
  padding: 0 15px;
  border-radius: 10px;
}
.drawer-content1 img {
  width: 50px;
  height: 50px;
  margin-bottom: 15px; /* Add some space below the photo */
  margin-top: 20px;
  /* background-color: red; */
  border-radius: 50%;
  object-fit: cover;
}

.drawer-content1 .name-div1 {
  padding-left: 11px;
  /* background-color: whitesmoke; */
  background-color: white;
}
.drawer-content1 p {
  font-size: 14px;
  /* background-color: whitesmoke; */
  background-color: white;
}

.drawer-content1 span {
  font-size: 11px;
  /* background-color: whitesmoke; */
  background-color: white;
}

.drawer-options1 {
  /* padding-top: 20px; */
  /* padding-bottom: 20px; */
  background-color: white;
  /* border: 1px solid black; */
  border-radius: 5px;
}

.drawer-option1 {
  background-color: white;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  transition: background-color 0.3s ease;
}

.drawer-option1 svg {
  background-color: white;
  width: 19px;
  transition: background-color 0.3s ease;
}

.drawer-option1 span {
  background-color: white;
  font-size: 11px;
  font-weight: 500;
  padding-left: 5px;

  transition: background-color 0.3s ease;
}

.drawer-option1:hover {
  background-color: lightgray;
  border-radius: 5px;
  cursor: pointer;
}
.drawer-option1:hover svg {
  background-color: lightgray;
}
.drawer-option1:hover span {
  background-color: lightgray;
}

.logout-btn1 {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-top: 10px; */
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.logout-btn1 svg {
  background-color: white;
  width: 19px;
  transition: background-color 0.3s ease;
}

.logout-btn1 button {
  background-color: white;
  border: 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.logout-btn1:hover {
  background-color: lightgray;
  border-radius: 5px;
}

.logout-btn1:hover svg {
  background-color: lightgray;
}
.logout-btn1:hover button {
  background-color: lightgray;
}

/* Setting Drwawe */

.custom-drawer-setting {
  position: fixed;
  top: 75px;
  margin-right: 15px;
  right: -400px;
  width: 300px;
  height: auto;
  /* background-color: whitesmoke; */
  background-color: white;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  transition: right 0.4s ease;
  z-index: 1100;
  border-radius: 5px;
  overflow-y: auto;
  padding: 15px 20px;
}

.custom-drawer-setting.open {
  right: 0;
}

.drawer-options-setting {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Add space between items */
  background-color: white;
  border-radius: 5px;
}

.drawer-option-setting {
  display: flex;
  align-items: flex-start; /* Align icon to the top */
  gap: 10px; /* Space between icon and text */
  padding: 10px 10px;
  background-color: white;
  transition: background-color 0.3s ease;
}

.drawer-option-setting svg {
  background-color: white;
  width: 19px;
  transition: background-color 0.3s ease;
}

.drawer-option-setting span {
  background-color: white;
  font-size: 11px;
  font-weight: 500;
  padding-left: 5px;

  transition: background-color 0.3s ease;
}

.drawer-option1-setting:hover {
  background-color: lightgray;
  border-radius: 5px;
  cursor: pointer;
}
.drawer-option-setting:hover svg {
  background-color: lightgray;
}
.drawer-option-setting:hover span {
  background-color: lightgray;
}

.drawer-icon-setting {
  flex-shrink: 0;
  width: 24px;
  height: 24px;
  /* color: #555;  */
  color: black; /* Customize icon color */
}

.drawer-texts-setting {
  display: flex;
  flex-direction: column;
  max-width: calc(100% - 40px); /* Ensure text fits within the layout */
}

.drawer-text-primary-setting {
  font-size: 14px;
  font-weight: bold;
  color: #333; /* Main text color */
}

.drawer-text-secondary-setting {
  font-size: 12px;
  color: #666; /* Secondary text color */
  padding-top: 2px;
  overflow-wrap: break-word; /* Ensures long text wraps */
  word-wrap: break-word; /* For older browsers */
  white-space: normal; /* Allow text to wrap to the next line */
  line-height: 1.5; /* Better readability */
}

/* STORAGE CONTAINER */

/* Storage Container */
.storage-container {
  margin: 10px 5px 10px 5px; /* Top, Right, Bottom, Left */
  background-color: white;
  padding: 10px;
  border-radius: 5px;
}

/* Storage Header Text */
.storage-text {
  font-size: 12px;
  font-weight: 500;
  color: #333;
  margin-bottom: 10px;
  background-color: white;

  display: flex;
  align-items: center;
  gap: 10px;
}

.cloud-icon {
  background-color: white;
  width: 19px;
}

/* Storage Bar */
.storage-bar {
  height: 5px;
  background-color: #f0f0f0;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;
}

.storage-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #1e88e5, #42a5f5);
  transition: width 0.5s ease-in-out;
}

/* Storage Info */
.storage-info {
  font-size: 11px;
  color: #666;
  margin-bottom: 15px;
  background-color: white;
  font-weight: 500;
}

/* Upgrade Button */
.storage-upgrade-btn {
  padding: 10px 16px;
  background: #318ce7;
  color: white;
  font-size: 11px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 8px rgba(66, 165, 245, 0.4);
  transition: transform 0.2s, box-shadow 0.2s, background-color 0.2s;
}

.storage-upgrade-btn.hover {
  background-color: #0066b2;
}

/* USER MODAL */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

/* Modal Container */
.modal-container {
  background: #fff;
  width: 900px;
  height: 270px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  position: relative;

  margin-top: -50px;
}

.modal-header {
  padding: 10px;
  background: #318ce7;
  color: white;
  font-size: 10px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.modal-header h2 {
  background-color: #318ce7;
}

.close-button {
  background: none;
  border: none;
  color: white;
  font-size: 1.5rem;
  cursor: pointer;
}

/* Custom Table */

.table-wrapper {
  width: calc(100% - 30px);
  margin: 15px 15px;
}

.table-container {
  max-height: 150px;
  overflow-y: auto;
  overflow-x: auto;
}

.table {
  table-layout: auto;

  border-radius: 10px;
  white-space: nowrap;
  margin: auto;
  font-size: 10px;
  width: 100%;
  border-collapse: collapse;
  font-size: 14px;
}

.table thead {
  background-color: #f4f4f4;
  text-align: left;
}

/* .table thead th { */
/* position: sticky; Keep header fixed */
/* top: 0; */
/* background-color: #f4f4f4;  */
/* background-color: white; */
/* z-index: 2; */
/* color: #555; */
/* font-weight: 600; */
/* font-size: 13px; */
/* padding: 0.8rem; */

/* padding: 15px; */
/* } */

.table thead th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 2;
  color: #555;
  font-weight: 600;
  font-size: 13px;
  /* padding: 0.8rem; */
}

.table td {
  font-size: 12px;
}

.table th,
.table td {
  /* padding: 0.8rem; */
  padding: 0.7rem;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table td {
  color: #333;
  background-color: white;
}

.table tr {
  cursor: pointer;
}

.label {
  border-radius: 3px;
  padding: 5px 8px;
  color: black;
  font-size: 12px;
}

.label-live {
  background-color: #32de84;
}

.label-no {
  background-color: #fd5c63;
}

.add-user-btn {
  padding: 7px 10px;
  margin: 5px 0 0 5px;
  background-color: #32de84;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.add-user-btn span {
  display: flex;
  align-items: center;
  background-color: #32de84;
  font-size: 12px;
  letter-spacing: 1px;
  gap: 5px;
}

.add-user-btn span svg {
  background-color: #32de84;
  width: 17px;
}

.delete-confirm-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2000;
}

.delete-confirm-container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.delete-confirm-container h3 {
  background-color: white;
  font-size: 13px;
}

.delete-confirm-container button {
  /* margin: 10px; */
  margin: 30px 0 0 0;
  padding: 10px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.delete-confirm-container .btn-yes {
  font-size: 11px;
  background-color: #32de84;
  color: white;
  margin-right: 50px;
  padding: 10px 20px;
}

.delete-confirm-container .btn-no {
  font-size: 11px;
  background-color: #fd5c63;
  color: white;
  padding: 10px 20px;
}

input[type="text"] {
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 14px;
  margin-right: 10px;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}

.table-container::-webkit-scrollbar {
  width: 4px; /* Width of the scrollbar */
  height: 4px;
}

.table-container::-webkit-scrollbar-thumb {
  background: gray; /* color */
  height: 3px;
  border-radius: 5px 5px 5px 5px; /* Round the corners of the scrollbar thumb */
}

.table-container::-webkit-scrollbar-thumb:hover {
  background: #155a8a; /* Color of the scrollbar thumb on hover */
}

.tooltip-container {
  position: relative;
  display: inline-block;
  cursor: pointer; /* Optional, adds pointer cursor */
  margin: 6px 0 0 0;
}

.tooltip-text {
  visibility: hidden; /* Initially hidden */
  opacity: 0; /* Fully transparent */
  background-color: black;
  color: white;
  text-align: center;
  padding: 5px 10px;
  border-radius: 5px;
  position: absolute;
  top: 30px; /* Position above the container */
  left: -25px;
  transform: translateX(-50%);
  white-space: nowrap;
  transition: opacity 0.2s ease, visibility 0.2s ease;
  z-index: 1000;
  font-size: 10px;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible; /* Show tooltip on hover */
  opacity: 1; /* Fade in tooltip */
}
/* .lang-button{
  display: flex;
  margin-left: 0px;
} */
