@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  background: linear-gradient(to right, #ece9e6, #ffffff);
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0;
  /* background-color: red; */
  padding: 20px;
}

.logo-container {
  background-color: white;
}

.login-box {
  background: #ffffff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  transition: transform 0.3s ease;
}

.login-box:hover {
  transform: translateY(-5px);
}

.logo-container {
  margin-bottom: 30px;
  background-color: white;
}

.login-logo {
  width: 55px;
  background-color: white;
  border-radius: 5px;
}

.login-form {
  width: 100%;
  background-color: white;
}

.login-input {
  /* width: 100%; */

  width: 94%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  background-color: #fafafa;
  transition: border-color 0.3s ease;
}

.login-input:focus {
  border-color: #1e1e1e;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 123, 255, 0.2);
}

.login-button {
  width: 100%;
  padding: 12px;
  background-color: #1e1e1e;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
}

.login-button:hover {
  background-color: #3c3c3c;
  box-shadow: 0 4px 12px rgba(0, 123, 255, 0.3);
}

.login-box p {
  font-size: 14px;
  color: #7d8597;
  text-align: right;
  margin-top: 15px;
  margin-bottom: 0;
}

.login-box p a {
  color: #0466c8;
  text-decoration: none;
}

.login-box p a:hover {
  text-decoration: underline;
}

.message {
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  text-align: center;
  background-color: white;
}

.para-login {
  background-color: white;
}

.message.success {
  color: #28a745;
}

.message.error {
  color: #dc3545;
}
